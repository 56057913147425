<template>
    <div>
        images
        <div>
            <table class="table table-hover" v-if="images && images.data">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>post_id</th>
                        <th>image local</th>
                        <th>image Remore</th>
                        <th>mate</th>
                        <th style="width: 300px;">action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in images.data" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.post_id }}</td>
                        <td>
                            <img :src="item.thumbnail" style="height: 100px;" alt="">
                        </td>
                        <td>
                            <img :src="item.path" style="height: 100px;" alt="">
                        </td>
                        <td>{{ item.meta }}</td>
                        <td>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                :data-bs-target="'#exampleModal-' + item.id">Edit</button>
                            <a href="#" type="button" class="btn btn-primary">Copy Path</a>
                            <a href="#" type="button" class="btn btn-primary">Copy Thumbnail</a>
                            <form id="update_image_file">
                                <div class="input-group mb-3">
                                    <input type="file" hidden name="image_file" class="form-control"
                                        id="inputGroupFile02">
                                    <label class="input-group-text" for="inputGroupFile02">Update file Thumbnail</label>
                                    <button class="btn btn-outline-secondary" type="button"
                                        @click="update_img_file(item.post_id, item.id)">Submit</button>
                                </div>
                            </form>
                            <a :href="'/shopnew/images/generateimagethumbnail/' + item.id" type="button"
                                class="btn btn-primary">Generate Thumbnail</a>
                            <button type="button" class="btn btn-primary" @click="image_delete(item.id)">Delete</button>
                        </td>
                        <td>{{ item.create }}</td>

                        <!-- Modal -->
                        <div class="modal fade" :id="'exampleModal-' + item.id" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        {{ item.id }}
                                        <form :id="'image_update-' + item.id" :ref="'image_update-' + item.id">
                                            <div class="row g-3">
                                                <label for="exampleDataList" class="form-label">Datalist example</label>
                                                <input class="form-control" list="datalistOptions" name="post_id"
                                                    id="exampleDataList" placeholder="Type to search..."
                                                    :value="item.post_id">
                                                <datalist id="datalistOptions">
                                                    <option v-for="(itemOptions, index) in products.data" :key="index"
                                                        :value="itemOptions.id">
                                                        {{ itemOptions.title }}
                                                    </option>
                                                </datalist>
                                            </div>
                                            <br><br>
                                            <div class="row g-3">
                                                <div class="col-auto">
                                                    <img :src="item.thumbnail" style="height: 100px;" alt="">
                                                    <img :src="item.path" style="height: 100px;" alt="">
                                                </div>
                                            </div>
                                            <div class="row g-3">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="flexCheckChecked" :checked="item.meta == 1"
                                                        :value="item.meta == 1 ? 0 : 1" name="meta">
                                                    <label class="form-check-label" for="flexCheckChecked">
                                                        Meta
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row g-3">
                                                <div class="mb-3">
                                                    <label for="exampleFormControlTextarea1" class="form-label">Path
                                                        300x300</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                                        rows="3" name="path" v-model="item.path"></textarea>
                                                    <button type="button" class="btn btn-primary"
                                                        @click="copyBody(item.path)">Copy</button>
                                                </div>
                                            </div>
                                            <div class="row g-3">
                                                <div class="mb-3">
                                                    <label for="exampleFormControlTextarea1"
                                                        class="form-label">Thumbnail</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                                        rows="3" name="thumbnail" v-model="item.thumbnail"></textarea>
                                                    <button type="button" class="btn btn-primary"
                                                        @click="copyBody(item.path)">Copy</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary"
                                            @click="image_update(item.id)">Save
                                            changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>


            <br><br>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            images: [],
            products: [],
        }
    },
    created() {
        this.getImages()
        this.getProducts()
    },
    methods: {
        getImages() {
            console.log("images")
            const url = this.appDomainApi + "shops/products/images/index" + window.location.search;

            axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.images = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getProducts() {
            // const url = this.appDomainApi + "shops/products/index?shop="+this.ticket.shop_store.name;
            const url = this.appDomainApi + "shops/products/index" + window.location.search;
            axios.get(url,)
                .then(res => {
                    console.log(res)
                    this.products = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        image_update(image_id) {
            console.log("image_id: " + image_id);

            let form = this.$refs['image_update-' + image_id][0];
            const formData = new FormData(form);

            // // Añadir el nombre del formulario al FormData
            // formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            formData.append('id', image_id);
            // console.log(...formData);
            // formData.append('slug', slug);

            const url = this.appDomainApi + "shops/products/images/store";

            axios.post(url, formData)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        },
        copyBody(content) {
            this.$store.dispatch('utils/global/copyToClipboard', content);
        }
    },

}
</script>
<style></style>